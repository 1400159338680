body{
    font-family: 'Poppins', sans-serif !important;
    --tw-bg-opacity: 1;
    background-color: rgb(0 4 15 / var(--tw-bg-opacity)) !important;
    cursor: default;
    overflow-x: hidden;
}

body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

body::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	background-color: #AAA;
	border-radius: 10px;
	background-image: -webkit-linear-gradient(90deg,
	                                          rgba(0, 0, 0, .2) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(0, 0, 0, .2) 50%,
											  rgba(0, 0, 0, .2) 75%,
											  transparent 75%,
											  transparent)
}


/* Styles for small screens and big screens*/
@media only screen and (max-width: 768px) {
    #form-inline {
        display: none;
    }
    .menu-toggle {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        margin-right: 20px;
    }
    .bar {
        width: 25px;
        height: 3px;
        background-color: #ffffff;
        margin: 3px 0;
    }
    .presentation-sec{
        display: block !important;
        font-size: 15px;
    }
    #div-presentation{
        margin-top: 150px;
        height: 100px;
    }
    #div-presentation h1{
        font-size: 45px;
    }
    .svg-animation{
        margin-left: 0px !important;
    }
    .ca{
        width: 100% !important;
    }
}
@media screen and (min-width: 768px) {
    #form-inline-responsive{
        display: none !important;
    }
}
@media only screen and (max-width: 1200px) {
    .ca {
        height: 100px;
        width: 400px;
    }
}

/*Styles for the nav bar */
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}
@keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
}
.fade-in {
    animation: fadeIn 0.5s;
    text-align: center;
    margin: 0 auto;
    flex-direction: column;
}
.fade-out {
    animation: fadeOut 0.6s !important;
    text-align: center;
    margin: 0 auto;
    flex-direction: column;
}

/*Form of the navbar */
#navbar{
    font-family: 'Eurostile', sans-serif;
    color: white;
}
.navbar-brand{
    color: white !important;
    margin-top: 20px;
    margin-left: 10%;
}
.form-inline{
    margin-right: 10%;
    display: flex;
    margin-top: 5px;
}
.form-inline h1{
    margin-left: 30px;
    font-size: 23px;
}
.form-inline h1 a{
    text-decoration: none;
    color: white;
}
.second-form-reponsive {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-inline-responsive {
    width: 100%;
    display: none;
    margin-top: 5px;
    text-align: center;
    position: absolute;
    margin-top: 30%;
    background-color: rgb(0 4 15 / var(--tw-bg-opacity));
}

.form-inline-responsive h1 {
    margin-left: 30px;
    font-size: 23px;
}

.form-inline-responsive h1 a {
    text-decoration: none;
    color: white;
}


/* Presentation section */
.presentation-sec{
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    color: white !important;
    margin-left: 10%;
    height: 80vh;
}
.div-presentation{
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.div-presentation h1{
    display: flex;
    align-items: center;
    font-size: 60px;
    white-space: nowrap;
}
.div-presentation p{
    opacity: 0.60;
}
.h1-jason-title{
    background: radial-gradient(64.18% 64.18% at 71.16% 35.69%,#def9fa .89%,#bef3f5 17.23%,#9dedf0 42.04%,#7de7eb 55.12%,#5ce1e6 71.54%,#33bbcf 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100% !important;
}
.svg-animation{
    margin-left: 100px;
}
.ca {
    height: 600px;
}

/* Skills section */
.skills-sec{
    height: 100vh;
    text-align: center;
    color: #f0f8ff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}
.skills-text{
    width: 50%;
    z-index: 2;
    position: relative;
}
.skills-text h1{
    font-size: 60px;
}
.skills-text p {
    font-size: 20px;
    margin-left: 35px;
}
.skills-div-logos{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}
.imageStyle{
    border-radius: 5px;
    width: 75px; 
    height: 80px;
    margin-left: 35px;
};
.skills-div-logos p{
    margin-top: 10px;
    margin-left: 45px;
}
.skills-div-logos div:hover{
    opacity: 0.8 !important;
    cursor: pointer;
}
.hyperlinkgithub{
    text-decoration: none;
    color: #6cb9dd;
}
.ImageBackground{
    filter: blur(15px);
    position: absolute;
    z-index: 1;
    width: 700px;
    height: 700px;
    margin-left: 1000px;
    object-fit: cover;
    transition: transform 0.3s ease-out;
}
.parallax {
    transform: translateY(500px); /* Ajustez la distance de déplacement selon l'effet de parallaxe souhaité */
    transition: transform 0.5s ease-out;
}

@media only screen and (max-width: 768px) {
    .skills-text h1{
        font-size: 45px;
    }
    .skills-text p {
        font-size: 15px;
    }
    .skills-text{
        width: 75%;
    }
    .ImageBackground{
        margin-left: 400px;
    }
    .skills-div-logos{
        display: flex;
        flex-wrap: wrap;
    }
    .imageStyle{
        width: 45px;
        height: 50px;
        margin-left: 10px;
    }
    .skills-div-logos p{
        margin-left: 10px;
    }
}

/* Projects section */
.projects-sec{
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #f0f8ff;
}
.projects-div-text{
    text-align: center;
}
.projects-div-text h1{
    font-size: 60px;
}
.projects-div-text p{
    font-size: 20px;
}

/* Projects div div animation */
.projects-div-div {
    display: block;
    align-items: center;
    justify-content: center;
}
.projects-div-div {
    height: 500px;
    width: 50%;
}

@media only screen and (max-width: 768px) {
    .projects-div-div {
        width: 75%;
    }
    .projects-div-text h1{
        font-size: 45px;
    }
    .projects-div-text p{
        font-size: 15px;
    }

}


/* FOOTER */

.jason-mourier-copyrights{
    margin-top: 100px;
    padding-bottom: 20px;
    text-align: center;
}
.jason-mourier-copyrights-h1{
    font-size: 20px;
    color: white;
}

@media only screen and (max-width: 768px) {
    .jason-mourier-copyrights{
        margin-top: 10px;
    }
}
@media only screen and (max-width: 1200px) {
    .jason-mourier-copyrights{
        margin-top: 170px;
    }
}

/* END FOOTER */