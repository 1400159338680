.main-div{
    text-align: center;
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100vh;
}
.main-div a{
    text-decoration: none;
    color: rgb(11, 101, 185);
}